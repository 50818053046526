import React from "react"
import { Link } from "gatsby"
import Button from "../components/Button"
import LogoInline from "../components/LogoInline"
import LogoStacked from "../components/LogoStacked"

function MainNav({ location, pages, ...props }) {
  return (
    <nav
      {...props}
      className={`flex flex-col items-center auto-cols-max space-y-32 mb-64 ${
        props.className ? props.className : ""
      }`}
    >
      <Link to="/">
        <LogoInline className="md:hidden max-w-full" />
        <LogoStacked className="hidden md:block" />
      </Link>

      <div className="grid grid-cols-2 md:grid-cols-1 gap-16 w-full md:w-3/5">
        {pages.map(page => (
          <Button to={page.to} iconStar active={location.pathname === page.to}>
            {page.title}
          </Button>
        ))}
      </div>
    </nav>
  )
}

export default MainNav
