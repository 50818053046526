import React from "react"

function IconCircle(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={80}
      height={80}
      viewBox="0 0 80 80"
      {...props}
      className={`fill-current h-auto ${
        props.className ? props.className : ""
      }`}
    >
      <path d="M0 40c22.091 0 40-17.909 40-40 0 22.091 17.909 40 40 40-22.091 0-40 17.909-40 40 0-22.091-17.909-40-40-40z" />
    </svg>
  )
}

export default IconCircle
