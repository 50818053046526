import React from "react"
import { Link } from "gatsby"
import IconStar from "./IconStar"
import IconCircle from "./IconCircle"

function Button({
  to,
  children,
  iconStar,
  iconCircle,
  active = false,
  ...props
}) {
  const iconStyles = `w-24 ${
    active ? "text-red-300" : "text-tan-200"
  } group-hover:text-red-300`

  return (
    <Link
      to={to}
      {...props}
      className={`px-16 py-4 block bg-tan-100 hover:shadow border border-tan-200 hover:border-tan-400 rounded-8 font-geared text-lg text-tan-500 flex justify-between items-center group ${
        iconCircle || iconStar ? "" : "w-max"
      } ${props.className ? props.className : ""}`}
    >
      <span className="pt-4 group-hover:text-red-300">{children}</span>

      {iconStar && <IconStar className={iconStyles} />}
      {iconCircle && <IconCircle className={iconStyles} />}
    </Link>
  )
}

export default Button
