import React from "react"

function IconCircle(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={80}
      height={80}
      viewBox="0 0 80 80"
      {...props}
      className={`fill-current h-auto ${
        props.className ? props.className : ""
      }`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 80c22.091 0 40-17.909 40-40S62.091 0 40 0 0 17.909 0 40s17.909 40 40 40zm.77-70.77c0 17.419-14.121 31.54-31.54 31.54 16.903 0 30.7 13.296 31.502 30h.074c.778-16.195 13.77-29.186 29.963-29.964v-.073c-16.703-.803-30-14.6-30-31.502z"
      />
    </svg>
  )
}

export default IconCircle
