import React from "react"
import MainNav from "./MainNav"
import texture from "../images/texture.png"

class Layout extends React.Component {
  render() {
    const { location, children, ...props } = this.props

    const pages = [{ title: "Home", to: "/" }]

    return (
      <>
        <div
          style={{ backgroundImage: `url(${texture})` }}
          {...props}
          className={`py-32 md:py-64 px-16 md:grid md:grid-flow-col bg-fixed bg-no-repeat bg-top bg-center ${
            props.className ? props.className : ""
          }`}
        >
          <MainNav pages={pages} location={location} />

          <main className="md:pt-96 max-w-lg">{children}</main>
        </div>

        <footer className="font-geared text-lg text-tan-200 px-16 py-36 md:p-64 uppercase">
          © {new Date().getFullYear()} Beyond Punching
        </footer>
      </>
    )
  }
}

export default Layout
